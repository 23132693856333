import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import { GenericObject } from '@utils/models/Types';
import { ButtonType } from '@components/atoms/buttons/components/button/enums/buttons.enum';
import { StepsFormGenericProps } from '@components/organisms/steps-form-module/steps-form-generic/steps-form-generic.enum';

@Component({
  selector: 'steps-form-generic',
  templateUrl: './steps-form-generic.component.html',
  styleUrl: './steps-form-generic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsFormGenericComponent {
  @Input() titleKey: string;
  @Input() formSectionList: any[];
  @Input() formSectionConfig: GenericObject<FormSection>;
  @Input() activeSection: any;
  @Input() isDisabledBackButton: boolean = false;
  @Input() isDisabledNextButton: boolean = false;
  @Input() isDisabledSaveButton: boolean = false;
  @Input() isDisabledFinishButton: boolean = true;
  @Input() showSaveButton: boolean = true;

  @Output() cancelClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() backClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSection: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitClick: EventEmitter<any> = new EventEmitter<any>();

  handleSectionChange(sectionId: any) {
    this.changeSection.emit(sectionId);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly StepsFormGenericProps = StepsFormGenericProps;
}
