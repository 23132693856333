import { inject } from '@angular/core';
import { ConfirmServiceService } from '@components/organisms/confirm-dialog/services/confirm-service.service';
import { ConfirmDialogProps } from '@components/organisms/confirm-dialog/confirm-dialog.enum';
import { DialogSize } from '@components/organisms/next-dialog/next-dialog.enum';
import { DistributorFormComponent } from '@pages/distributor/pages/distributor-edition/components/distributor-form/distributor-form.component';

export const dirtyFormGuard = (component: DistributorFormComponent) => {
  const confirmationService = inject(ConfirmServiceService);
  if (!component.activeFormControl?.pristine) {
    return confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'DISTRIBUTOR.CONFIRMATION.EXIT_FORM.TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'DISTRIBUTOR.CONFIRMATION.EXIT_FORM.SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRM.YES',
        [ConfirmDialogProps.CANCEL_LABEL_KEY]: 'CONFIRM.NO',
        [ConfirmDialogProps.SIZE]: DialogSize.SMALL,
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then(() => _exitAllowed())
      .catch(() => false);
  } else {
    return _exitAllowed();
  }
};

function _exitAllowed() {
  return true;
}
