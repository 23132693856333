<div class="steps-form-generic">
  <next-action-header [labelKey]="titleKey"></next-action-header>
  <div class="steps-form-generic__container">
    <div class="steps-form-generic__content">
      <div class="steps-form-generic__form-instructions">
        {{ 'REQUIRED_FIELD' | translate }}
      </div>
      <div class="steps-form-generic__form">
        <ng-content></ng-content>
      </div>
      <div class="steps-form-generic__control-buttons">
        <div class="steps-form-generic__control-buttons-group">
          <next-button
            [labelKey]="'Cancel'"
            [type]="ButtonType.SECONDARY"
            (clickEvent)="cancelClick.emit()"
            [testIdDataAttr]="StepsFormGenericProps.FORM_CANCEL"
          ></next-button>
          <next-button *ngIf="showSaveButton"
            [labelKey]="'Save'"
            [type]="ButtonType.SECONDARY"
            [isDisabled]="isDisabledSaveButton"
            [testIdDataAttr]="StepsFormGenericProps.FORM_SAVE"
            (clickEvent)="saveClick.emit()"
          ></next-button>
        </div>
        <div *ngIf="formSectionList.length > 1" class="steps-form-generic__control-buttons-group">
          <next-button
            [labelKey]="'Back'"
            [isDisabled]="isDisabledBackButton"
            (clickEvent)="backClick.emit()"
            [testIdDataAttr]="StepsFormGenericProps.FORM_BACK"
          ></next-button>
          <next-button
            [labelKey]="'Next'"
            [isDisabled]="isDisabledNextButton"
            (clickEvent)="nextClick.emit()"
            [testIdDataAttr]="StepsFormGenericProps.FORM_NEXT"
          ></next-button>
        </div>
      </div>
    </div>
    <div class="steps-form-generic__sections">
      <form-menu-section
        [formSectionList]="formSectionList"
        [formSectionConfig]="formSectionConfig"
        [activeFormSection]="activeSection"
        [isDisabledFinishButton]="isDisabledFinishButton"
        (sectionClick)="handleSectionChange($event)"
        (submitClick)="submitClick.emit()"
      ></form-menu-section>
    </div>
  </div>
</div>
