import { EndpointsConstant } from '../../../core/constants/endpoint.constant';

export class SourceLocationEndpointsConstant {
  static config = {
    LIST: {
      url: `source-locations`
    },
    ITEM: {
      url: `source-locations/${EndpointsConstant.genericIdParamSelector}`
    }
  };
}
