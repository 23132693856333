import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, Injector, OnInit, signal } from '@angular/core';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { GenericObject } from '@utils/models/Types';
import { AbstractControl, FormGroup } from '@angular/forms';
import { filter, Subscription } from 'rxjs';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormSectionProps, FormSectionStatus } from '@components/organisms/layout/enum/form-section.enum';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import { NavigationService } from '@services/navigation.service';
import { NextRouteItem } from '../../../../core/types/route.type';
import { RouteParams } from '../../../../core/enums/route.enum';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastMsgService } from '@services/toast-msg.service';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { RoutingConfigConstant } from '../../../../core/constants/routing-config.constant';
import { NextStringHelper } from '@utils/core/next-string.helper';
import { PatternConstant } from '../../../../core/constants/pattern.constant';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsFormAbstractComponent<SectionType> extends GenericBaseComponent implements OnInit {
  id: any;
  isEdition: boolean;
  isTaskBoard: boolean;
  formGroup: FormGroup;
  activeFormControl: AbstractControl | null;
  formSectionList: any[];
  activeSectionSig: any;
  isNextFlag: boolean;
  formStatusChangesSubscription: Subscription;
  isFormSectionDefaultDisabled: boolean = true;
  formSectionConfig: GenericObject<FormSection>;
  computedFormSectionConfig: GenericObject<FormSection> = {};
  formSectionConfigByPath: GenericObject<any> = {};
  redirectRouteConfig: NextRouteItem;
  groupFormRouteConfig: NextRouteItem;
  ready: boolean;

  mapSections: GenericObject;
  _minIndex: number = 0;
  _maxIndex: number;
  protected _cdr: ChangeDetectorRef;
  protected _navigationService: NavigationService;
  protected _router: Router;
  protected _activatedRoute: ActivatedRoute;
  protected _toastService;

  constructor(injector: Injector) {
    super();
    this._cdr = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this._navigationService = injector.get<NavigationService>(NavigationService);
    this._router = injector.get<Router>(Router);
    this._activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this._toastService = injector.get<ToastMsgService>(ToastMsgService);
    this._registerEffects();
  }

  ngOnInit(): void {
    const url = this._router.routerState.snapshot.url;
    this.id = NextObjectHelper.getPropertyFromObject(this._activatedRoute.snapshot.params, [RouteParams.ID]);
    this.isTaskBoard = !NextStringHelper.hasPatternMatch(this.id, PatternConstant.numericPattern) || NextStringHelper.hasPatternMatch(url, TaskBoardRouteConfig.mainPath);
    this.isEdition = !!this.id && this.id !== RoutingConfigConstant.newParam;
  }

  initialize() {
    this._initializeStepControls();
    this._subscribeToNavigationChanges();
    const sectionIndex = this._findSectionToActivate();
    this._setMenuSectionInitialConfig(sectionIndex);
    this._initializeActivateSection(sectionIndex);
    this._navigateToSectionIndex(sectionIndex);
    this.ready = true;
  }

  setActiveSection(sectionId: any) {
    this.computedFormSectionConfig[this.activeSectionSig()][FormSectionProps.STATUS] = FormSectionStatus.COMPLETED;
    this.activeSectionSig.set(sectionId);
    this.setActiveForm();
    this._cdr.markForCheck();
  }

  changeSection(sectionId: any) {
    this._navigationService
      .navigateToSibling(
        [NextObjectHelper.getPropertyFromObject(this.computedFormSectionConfig, [sectionId, FormSectionProps.PATH])],
        this._activatedRoute
      )
      .then();
  }

  handleBackButton() {
    const activeIndex = this.mapSections[this.activeSectionSig()];
    const nextIndex = this._minIndex === activeIndex ? this._minIndex : activeIndex - 1;
    this.changeSection(this.formSectionList[nextIndex]);
  }

  handleCancelButton() {
    this.redirectToList();
  }

  redirectToList() {
    this._navigationService.navigateToRouteConfig(this.redirectRouteConfig);
  }

  protected updateSection() {
    const activeIndex = this.mapSections[this.activeSectionSig()];
    this.changeSection(this.formSectionList[activeIndex]);
  }

  protected goToNextSection() {
    const activeIndex = this.mapSections[this.activeSectionSig()];
    const nextIndex = this._maxIndex === activeIndex ? this._maxIndex : activeIndex + 1;
    this.changeSection(this.formSectionList[nextIndex]);
  }

  protected handleSaveSuccess() {
    this._showSuccessMsg();
  }

  protected _subscribeFormChanges() {
    if (this.formStatusChangesSubscription) {
      this.formStatusChangesSubscription.unsubscribe();
    }
    const formSectionAbstractControl = this.formGroup.get(this.activeSectionSig());

    const statusChange$ = NextFormHelper.createStatusChangeObs(formSectionAbstractControl!);
    this.formStatusChangesSubscription = statusChange$.subscribe(() => {
      this._cdr.markForCheck();
    });
  }

  private _showSuccessMsg() {
    this._toastService.success('API.SAVE_SUCCESS');
  }

  private _registerEffects() {
    effect(() => {
      if (this.formGroup) {
        this._subscribeFormChanges();
      }
    });
  }

  private _initializeStepControls() {
    this.mapSections = this.formSectionList.reduce((acc, section, index) => {
      this.formSectionConfigByPath[this.formSectionConfig[section][FormSectionProps.PATH]] = {
        ...this.formSectionConfig[section],
        [FormSectionProps.ID]: section
      };
      acc[section] = index;
      return acc;
    }, {});
  }

  private _findSectionToActivate() {
    const requestedSectionPath = NextObjectHelper.getPropertyFromObject(this._activatedRoute.snapshot.params, [
      RouteParams.STEP_FORM
    ]);
    const requestedSection = NextObjectHelper.getPropertyFromObject(
      this.formSectionConfigByPath,
      [requestedSectionPath, FormSectionProps.ID],
      this.formSectionList[0]
    );
    const sectionIndex = this.mapSections[requestedSection];
    if (sectionIndex === 0 || !this.isFormSectionDefaultDisabled) {
      return sectionIndex;
    } else {
      return this._validateSectionAvailability(sectionIndex);
    }
  }

  private _validateSectionAvailability(sectionIndex: number) {
    let suggestedSectionIndex: number;
    let validSection = null;
    for (let i = 0; i < sectionIndex; i++) {
      validSection = this.formGroup.get(this.formSectionList[i])?.valid;
      if (!validSection || i === sectionIndex) {
        suggestedSectionIndex = i;
        return suggestedSectionIndex;
      }
    }
    return sectionIndex;
  }

  private _initializeActivateSection(sectionIndex: number) {
    const section = this.formSectionList[sectionIndex];
    this.activeSectionSig = signal<SectionType>(section);
    this._maxIndex = this.formSectionList.length - 1;

    this.setActiveForm();
  }

  private _subscribeToNavigationChanges() {
    const subs = this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const sectionIndex = this._findSectionToActivate();
      this.setActiveSection(this.formSectionList[sectionIndex]);
    })
    this.subs.push(subs);
  }

  private _navigateToSectionIndex(sectionIndex: number) {
    const section = this.formSectionList[sectionIndex];
    this.changeSection(section);
  }

  private _setMenuSectionInitialConfig(initialSectionIndex: number) {
    const initialSection = this.formSectionList[initialSectionIndex];
    for (const section of this.formSectionList) {
      this.computedFormSectionConfig[section] = {
        ...this.formSectionConfig[section],
        [FormSectionProps.STATUS]: this._getFormStatus(section, initialSection)
      };
    }
  }

  private _getFormStatus(section: any, initialSection: any) {
    if (!this.isFormSectionDefaultDisabled) {
      return FormSectionStatus.ENABLED;
    } else {
      const sectionIndex = this.mapSections[section];
      const activeSectionIndex = this.mapSections[initialSection];
      if (sectionIndex < activeSectionIndex) {
        return FormSectionStatus.ENABLED;
      } else {
        return FormSectionStatus.DISABLED;
      }
    }
  }

  private setActiveForm() {
    this.activeFormControl = this.formGroup.get(this.activeSectionSig());
  }
}
